/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/lawers.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_lawer_pb = require('./lawer_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./lawers_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.LawersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.LawersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListLawersRequest,
 *   !proto.dating.erp.api.v1.services.ListLawersResponse>}
 */
const methodDescriptor_Lawers_ListLawers = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Lawers/ListLawers',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListLawersRequest,
  proto.dating.erp.api.v1.services.ListLawersResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListLawersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListLawersResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListLawersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListLawersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListLawersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LawersClient.prototype.listLawers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/ListLawers',
      request,
      metadata || {},
      methodDescriptor_Lawers_ListLawers,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListLawersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListLawersResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LawersPromiseClient.prototype.listLawers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/ListLawers',
      request,
      metadata || {},
      methodDescriptor_Lawers_ListLawers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetLawerRequest,
 *   !proto.dating.erp.api.v1.model.Lawer>}
 */
const methodDescriptor_Lawers_GetLawer = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Lawers/GetLawer',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetLawerRequest,
  projects_erp_service_api_v1_model_lawer_pb.Lawer,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetLawerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_lawer_pb.Lawer.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetLawerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Lawer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Lawer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LawersClient.prototype.getLawer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/GetLawer',
      request,
      metadata || {},
      methodDescriptor_Lawers_GetLawer,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetLawerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Lawer>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LawersPromiseClient.prototype.getLawer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/GetLawer',
      request,
      metadata || {},
      methodDescriptor_Lawers_GetLawer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyLawerRequest,
 *   !proto.dating.erp.api.v1.model.Lawer>}
 */
const methodDescriptor_Lawers_ApplyLawer = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Lawers/ApplyLawer',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyLawerRequest,
  projects_erp_service_api_v1_model_lawer_pb.Lawer,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyLawerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_lawer_pb.Lawer.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.Lawer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.Lawer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LawersClient.prototype.applyLawer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/ApplyLawer',
      request,
      metadata || {},
      methodDescriptor_Lawers_ApplyLawer,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.Lawer>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LawersPromiseClient.prototype.applyLawer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/ApplyLawer',
      request,
      metadata || {},
      methodDescriptor_Lawers_ApplyLawer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteLawerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Lawers_DeleteLawer = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.Lawers/DeleteLawer',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteLawerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteLawerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.LawersClient.prototype.deleteLawer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/DeleteLawer',
      request,
      metadata || {},
      methodDescriptor_Lawers_DeleteLawer,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.LawersPromiseClient.prototype.deleteLawer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.Lawers/DeleteLawer',
      request,
      metadata || {},
      methodDescriptor_Lawers_DeleteLawer);
};


module.exports = proto.dating.erp.api.v1.services;

