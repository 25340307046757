// ** React Imports
import { lazy } from 'react'

const InvoiceList = lazy(() => import('views/financial/invoices/list'))
const InvoiceAdd = lazy(() => import('views/financial/invoices/add'))
const InvoiceView = lazy(() => import('views/financial/invoices/view'))
const InvoiceEdit = lazy(() => import('views/financial/invoices/edit'))
const InvoicePrint = lazy(() => import('views/financial/invoices/print'))
const InvoicePayeeList = lazy(() => import('views/financial/invoice-payees/list'))
const PaymentList = lazy(() => import('views/financial/payments/list'))
const PaymentImport = lazy(() => import('views/financial/payments/import'))
const PaymentAdd = lazy(() => import('views/financial/payments/add'))
const PaymentView = lazy(() => import('views/financial/payments/view'))
const PaymentEdit = lazy(() => import('views/financial/payments/edit'))
const CompanyList = lazy(() => import('views/financial/companies/list'))

const FinancialRoutes = [
  {
    element: <InvoiceAdd />,
    path: '/invoices/-/add',
    meta: {
      resource: 'invoices',
      action: 'add'
    }
  },
  {
    element: <InvoiceView />,
    path: '/invoices/:id/view',
    meta: {
      resource: 'invoices',
      action: 'read'
    }
  },
  {
    element: <InvoiceEdit />,
    path: '/invoices/:id/edit',
    meta: {
      resource: 'invoices',
      action: 'manage'
    }
  },
  {
    element: <InvoicePrint />,
    path: '/invoices/:id/print',
    meta: {
      layout: 'blank',
      resource: 'invoices',
      action: 'read'
    }
  },
  {
    element: <PaymentImport />,
    path: '/payments/-/import',
    meta: {
      resource: 'payments',
      action: 'add'
    }
  },
  {
    element: <PaymentAdd />,
    path: '/payments/-/add',
    meta: {
      resource: 'payments',
      action: 'add'
    }
  },
  {
    element: <PaymentView />,
    path: '/payments/:id/view',
    meta: {
      resource: 'payments',
      action: 'read'
    }
  },
  {
    element: <PaymentEdit />,
    path: '/payments/:id/edit',
    meta: {
      resource: 'payment_orders',
      action: 'manage'
    }
  },
  {
    element: <InvoiceList />,
    path: '/invoices',
    meta: {
      resource: 'invoices',
      action: 'read'
    }
  },
  {
    element: <CompanyList />,
    path: '/companies',
    meta: {
      resource: 'companies',
      action: 'read'
    }
  },
  {
    element: <PaymentList />,
    path: '/payments',
    meta: {
      resource: 'payments',
      action: 'read'
    }
  },
  {
    element: <InvoicePayeeList />,
    path: '/beneficiaries',
    meta: {
      resource: 'invoice_payees',
      action: 'read'
    }
  }
]

export default FinancialRoutes
