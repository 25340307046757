/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/email_message_template_params.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_email_message_template_param_pb = require('./email_message_template_param_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./email_message_template_params_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsRequest,
 *   !proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsResponse>}
 */
const methodDescriptor_EmailMessageTemplateParams_ListEmailMessageTemplateParams = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplateParams/ListEmailMessageTemplateParams',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsRequest,
  proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsClient.prototype.listEmailMessageTemplateParams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/ListEmailMessageTemplateParams',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_ListEmailMessageTemplateParams,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListEmailMessageTemplateParamsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsPromiseClient.prototype.listEmailMessageTemplateParams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/ListEmailMessageTemplateParams',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_ListEmailMessageTemplateParams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetEmailMessageTemplateParamRequest,
 *   !proto.dating.erp.api.v1.model.EmailMessageTemplateParam>}
 */
const methodDescriptor_EmailMessageTemplateParams_GetEmailMessageTemplateParam = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplateParams/GetEmailMessageTemplateParam',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetEmailMessageTemplateParamRequest,
  projects_erp_service_api_v1_model_email_message_template_param_pb.EmailMessageTemplateParam,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetEmailMessageTemplateParamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_email_message_template_param_pb.EmailMessageTemplateParam.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.EmailMessageTemplateParam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.EmailMessageTemplateParam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsClient.prototype.getEmailMessageTemplateParam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/GetEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_GetEmailMessageTemplateParam,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.EmailMessageTemplateParam>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsPromiseClient.prototype.getEmailMessageTemplateParam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/GetEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_GetEmailMessageTemplateParam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateParamRequest,
 *   !proto.dating.erp.api.v1.model.EmailMessageTemplateParam>}
 */
const methodDescriptor_EmailMessageTemplateParams_ApplyEmailMessageTemplateParam = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplateParams/ApplyEmailMessageTemplateParam',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateParamRequest,
  projects_erp_service_api_v1_model_email_message_template_param_pb.EmailMessageTemplateParam,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateParamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_email_message_template_param_pb.EmailMessageTemplateParam.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.EmailMessageTemplateParam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.EmailMessageTemplateParam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsClient.prototype.applyEmailMessageTemplateParam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/ApplyEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_ApplyEmailMessageTemplateParam,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.EmailMessageTemplateParam>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsPromiseClient.prototype.applyEmailMessageTemplateParam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/ApplyEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_ApplyEmailMessageTemplateParam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateParamRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EmailMessageTemplateParams_DeleteEmailMessageTemplateParam = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplateParams/DeleteEmailMessageTemplateParam',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateParamRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateParamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsClient.prototype.deleteEmailMessageTemplateParam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/DeleteEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_DeleteEmailMessageTemplateParam,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsPromiseClient.prototype.deleteEmailMessageTemplateParam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/DeleteEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_DeleteEmailMessageTemplateParam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamRequest,
 *   !proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamResponse>}
 */
const methodDescriptor_EmailMessageTemplateParams_GenerateEmailMessageTemplateParam = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailMessageTemplateParams/GenerateEmailMessageTemplateParam',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamRequest,
  proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsClient.prototype.generateEmailMessageTemplateParam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/GenerateEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_GenerateEmailMessageTemplateParam,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.GenerateEmailMessageTemplateParamResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailMessageTemplateParamsPromiseClient.prototype.generateEmailMessageTemplateParam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailMessageTemplateParams/GenerateEmailMessageTemplateParam',
      request,
      metadata || {},
      methodDescriptor_EmailMessageTemplateParams_GenerateEmailMessageTemplateParam);
};


module.exports = proto.dating.erp.api.v1.services;

