// source: projects/erp/service/api/v1/services/lawer_categories.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var projects_erp_service_api_v1_model_lawer_category_pb = require('./lawer_category_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_lawer_category_pb);
goog.exportSymbol('proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.GetLawerCategoryRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.ListLawerCategoriesRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.ListLawerCategoriesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ListLawerCategoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.displayName = 'proto.dating.erp.api.v1.services.ListLawerCategoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ListLawerCategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.displayName = 'proto.dating.erp.api.v1.services.ListLawerCategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.GetLawerCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.GetLawerCategoryRequest.displayName = 'proto.dating.erp.api.v1.services.GetLawerCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.displayName = 'proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.displayName = 'proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ListLawerCategoriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ListLawerCategoriesRequest}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ListLawerCategoriesRequest;
  return proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ListLawerCategoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ListLawerCategoriesRequest}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ListLawerCategoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ListLawerCategoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lawerCategoriesList: jspb.Message.toObjectList(msg.getLawerCategoriesList(),
    projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ListLawerCategoriesResponse}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ListLawerCategoriesResponse;
  return proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ListLawerCategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ListLawerCategoriesResponse}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory;
      reader.readMessage(value,projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory.deserializeBinaryFromReader);
      msg.addLawerCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ListLawerCategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLawerCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dating.erp.api.v1.model.LawerCategory lawer_categories = 1;
 * @return {!Array<!proto.dating.erp.api.v1.model.LawerCategory>}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.prototype.getLawerCategoriesList = function() {
  return /** @type{!Array<!proto.dating.erp.api.v1.model.LawerCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory, 1));
};


/**
 * @param {!Array<!proto.dating.erp.api.v1.model.LawerCategory>} value
 * @return {!proto.dating.erp.api.v1.services.ListLawerCategoriesResponse} returns this
*/
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.prototype.setLawerCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dating.erp.api.v1.model.LawerCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dating.erp.api.v1.model.LawerCategory}
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.prototype.addLawerCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dating.erp.api.v1.model.LawerCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dating.erp.api.v1.services.ListLawerCategoriesResponse} returns this
 */
proto.dating.erp.api.v1.services.ListLawerCategoriesResponse.prototype.clearLawerCategoriesList = function() {
  return this.setLawerCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.GetLawerCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.GetLawerCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.GetLawerCategoryRequest}
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.GetLawerCategoryRequest;
  return proto.dating.erp.api.v1.services.GetLawerCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.GetLawerCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.GetLawerCategoryRequest}
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.GetLawerCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.GetLawerCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.GetLawerCategoryRequest} returns this
 */
proto.dating.erp.api.v1.services.GetLawerCategoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lawerCategory: (f = msg.getLawerCategory()) && projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest}
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest;
  return proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest}
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory;
      reader.readMessage(value,projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory.deserializeBinaryFromReader);
      msg.setLawerCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLawerCategory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional dating.erp.api.v1.model.LawerCategory lawer_category = 1;
 * @return {?proto.dating.erp.api.v1.model.LawerCategory}
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.prototype.getLawerCategory = function() {
  return /** @type{?proto.dating.erp.api.v1.model.LawerCategory} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_lawer_category_pb.LawerCategory, 1));
};


/**
 * @param {?proto.dating.erp.api.v1.model.LawerCategory|undefined} value
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest} returns this
*/
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.prototype.setLawerCategory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest} returns this
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.prototype.clearLawerCategory = function() {
  return this.setLawerCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.services.ApplyLawerCategoryRequest.prototype.hasLawerCategory = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest}
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest;
  return proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest}
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest} returns this
 */
proto.dating.erp.api.v1.services.DeleteLawerCategoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.dating.erp.api.v1.services);
