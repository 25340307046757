// ** React Imports
import { lazy } from 'react'

const CustomersList = lazy(() => import('views/users/customers/list'))
const CustomersView = lazy(() => import('views/users/customers/view'))
const CustomersEdit = lazy(() => import('views/users/customers/edit'))
const Chat = lazy(() => import('views/activities/chat'))
const Email = lazy(() => import('views/activities/email'))
const AffilatesList = lazy(() => import('views/users/affilates/list'))
const LawersList = lazy(() => import('views/users/lawers/list'))

const CustomersRoutes = [
  {
    element: <CustomersView />,
    path: '/customers/:id/view',
    meta: {
      resource: 'customers',
      action: 'read'
    }
  },
  {
    element: <CustomersEdit />,
    path: '/customers/:id/validate',
    meta: {
      resource: 'customers',
      action: 'read'
    }
  },
  {
    element: <CustomersList />,
    path: '/verifying/customers',
    meta: {
      resource: 'customers',
      action: 'read'
    }
  },
  {
    element: <CustomersList />,
    path: '/customers',
    meta: {
      resource: 'customers',
      action: 'read'
    }
  },
  {
    element: <AffilatesList />,
    path: '/affilates',
    meta: {
      resource: 'affilates',
      action: 'read'
    }
  },
  {
    path: '/chat',
    element: <Chat />,
    meta: {
      appLayout: true,
      className: 'chat-application'
    }
  },
  {
    path: '/email/customers/:customer/:folder',
    element: <Email />,
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    path: '/email/:id/view',
    element: <Email />,
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    path: '/email/:folder',
    element: <Email />,
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    path: '/email',
    element: <Email />,
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    element: <LawersList />,
    path: '/lawers',
    meta: {
      resource: 'lawers',
      action: 'read'
    }
  }
]

export default CustomersRoutes
