import { ApiUrl, getMetadata } from '../index'
import { EmailMessageTemplate } from './email_message_template_pb'
import {
  GetEmailMessageTemplateRequest,
  ListEmailMessageTemplatesRequest,
  ApplyEmailMessageTemplateRequest,
  DeleteEmailMessageTemplateRequest
} from './email_message_templates_pb'
import { EmailMessageTemplatesClient } from './email_message_templates_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new EmailMessageTemplatesClient(ApiUrl, null, null);

const toEmailMessageTemplateModel = (instance) => {
  let result = new EmailMessageTemplate()
  result.setName(instance.name)
  
  result.setDisplayName(instance.displayName)
  if (instance.bodyHtml) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.bodyHtml)
    result.setBodyHtml(wrapped)
  }
  if (instance.bodyText) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.bodyText)
    result.setBodyText(wrapped)
  }
  result.setIsActive(instance.isActive)
  result.setType(instance.type)

  return result
}

const toEmailMessageTemplateObject = (instance) => {
  if (!instance) return instance
  return {
    ...instance,
    bodyHtml: instance?.bodyHtml?.value,
    bodyText: instance?.bodyText?.value
  }
}

const API = {
  listEmailMessageTemplates: (params) => {
    const request = new ListEmailMessageTemplatesRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    
    return new Promise((resolve, reject) => {
      client.listEmailMessageTemplates(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            emailMessageTemplatesList: result.emailMessageTemplatesList.map(e => toEmailMessageTemplateObject(e))
          })
        }
      });
    });
  },
  getEmailMessageTemplate: (name) => {
    const request = new GetEmailMessageTemplateRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getEmailMessageTemplate(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toEmailMessageTemplateObject(response.toObject()))
        }
      });
    });
  },
  applyEmailMessageTemplate: (instance) => {
    const applying = toEmailMessageTemplateModel(instance)
    const request = new ApplyEmailMessageTemplateRequest()
    request.setEmailMessageTemplate(applying)
    return new Promise((resolve, reject) => {
      client.applyEmailMessageTemplate(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteEmailMessageTemplate: (name) => {
    const request = new DeleteEmailMessageTemplateRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteEmailMessageTemplate(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
