// source: projects/erp/service/api/v1/services/lawers.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var projects_erp_service_api_v1_model_lawer_pb = require('./lawer_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_lawer_pb);
goog.exportSymbol('proto.dating.erp.api.v1.services.ApplyLawerRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.DeleteLawerRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.GetLawerRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.ListLawersRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.ListLawersResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ListLawersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ListLawersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ListLawersRequest.displayName = 'proto.dating.erp.api.v1.services.ListLawersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ListLawersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dating.erp.api.v1.services.ListLawersResponse.repeatedFields_, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ListLawersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ListLawersResponse.displayName = 'proto.dating.erp.api.v1.services.ListLawersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.GetLawerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.GetLawerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.GetLawerRequest.displayName = 'proto.dating.erp.api.v1.services.GetLawerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ApplyLawerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ApplyLawerRequest.displayName = 'proto.dating.erp.api.v1.services.ApplyLawerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.DeleteLawerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.DeleteLawerRequest.displayName = 'proto.dating.erp.api.v1.services.DeleteLawerRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ListLawersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ListLawersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    perPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ListLawersRequest;
  return proto.dating.erp.api.v1.services.ListLawersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ListLawersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortColumn(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSort(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ListLawersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ListLawersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSortColumn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 per_page = 1;
 * @return {number}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest} returns this
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest} returns this
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sort_column = 3;
 * @return {string}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.getSortColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest} returns this
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool sort = 4;
 * @return {boolean}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.getSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest} returns this
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest} returns this
*/
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.services.ListLawersRequest} returns this
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.services.ListLawersRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dating.erp.api.v1.services.ListLawersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ListLawersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ListLawersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lawersList: jspb.Message.toObjectList(msg.getLawersList(),
    projects_erp_service_api_v1_model_lawer_pb.Lawer.toObject, includeInstance),
    totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ListLawersResponse}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ListLawersResponse;
  return proto.dating.erp.api.v1.services.ListLawersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ListLawersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ListLawersResponse}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_lawer_pb.Lawer;
      reader.readMessage(value,projects_erp_service_api_v1_model_lawer_pb.Lawer.deserializeBinaryFromReader);
      msg.addLawers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRows(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ListLawersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ListLawersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListLawersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLawersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_lawer_pb.Lawer.serializeBinaryToWriter
    );
  }
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated dating.erp.api.v1.model.Lawer lawers = 1;
 * @return {!Array<!proto.dating.erp.api.v1.model.Lawer>}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.getLawersList = function() {
  return /** @type{!Array<!proto.dating.erp.api.v1.model.Lawer>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_lawer_pb.Lawer, 1));
};


/**
 * @param {!Array<!proto.dating.erp.api.v1.model.Lawer>} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersResponse} returns this
*/
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.setLawersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dating.erp.api.v1.model.Lawer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dating.erp.api.v1.model.Lawer}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.addLawers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dating.erp.api.v1.model.Lawer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dating.erp.api.v1.services.ListLawersResponse} returns this
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.clearLawersList = function() {
  return this.setLawersList([]);
};


/**
 * optional int64 total_rows = 2;
 * @return {number}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersResponse} returns this
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_pages = 3;
 * @return {number}
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dating.erp.api.v1.services.ListLawersResponse} returns this
 */
proto.dating.erp.api.v1.services.ListLawersResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.GetLawerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.GetLawerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.GetLawerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.GetLawerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.GetLawerRequest}
 */
proto.dating.erp.api.v1.services.GetLawerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.GetLawerRequest;
  return proto.dating.erp.api.v1.services.GetLawerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.GetLawerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.GetLawerRequest}
 */
proto.dating.erp.api.v1.services.GetLawerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.GetLawerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.GetLawerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.GetLawerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.GetLawerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.GetLawerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.GetLawerRequest} returns this
 */
proto.dating.erp.api.v1.services.GetLawerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ApplyLawerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lawer: (f = msg.getLawer()) && projects_erp_service_api_v1_model_lawer_pb.Lawer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerRequest}
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ApplyLawerRequest;
  return proto.dating.erp.api.v1.services.ApplyLawerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerRequest}
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_lawer_pb.Lawer;
      reader.readMessage(value,projects_erp_service_api_v1_model_lawer_pb.Lawer.deserializeBinaryFromReader);
      msg.setLawer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ApplyLawerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ApplyLawerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLawer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_lawer_pb.Lawer.serializeBinaryToWriter
    );
  }
};


/**
 * optional dating.erp.api.v1.model.Lawer Lawer = 1;
 * @return {?proto.dating.erp.api.v1.model.Lawer}
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.prototype.getLawer = function() {
  return /** @type{?proto.dating.erp.api.v1.model.Lawer} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_lawer_pb.Lawer, 1));
};


/**
 * @param {?proto.dating.erp.api.v1.model.Lawer|undefined} value
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerRequest} returns this
*/
proto.dating.erp.api.v1.services.ApplyLawerRequest.prototype.setLawer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.services.ApplyLawerRequest} returns this
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.prototype.clearLawer = function() {
  return this.setLawer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.services.ApplyLawerRequest.prototype.hasLawer = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.DeleteLawerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.DeleteLawerRequest}
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.DeleteLawerRequest;
  return proto.dating.erp.api.v1.services.DeleteLawerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.DeleteLawerRequest}
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.DeleteLawerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.DeleteLawerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.DeleteLawerRequest} returns this
 */
proto.dating.erp.api.v1.services.DeleteLawerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.dating.erp.api.v1.services);
