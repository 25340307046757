import { ApiUrl, getMetadata } from '../index'
import { Lawer } from './lawer_pb'
import {
  GetLawerRequest,
  ListLawersRequest,
  ApplyLawerRequest,
  DeleteLawerRequest
} from './lawers_pb'
import { LawersClient } from './lawers_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new LawersClient(ApiUrl, null, null);

const toLawerModel = (instance) => {
  let result = new Lawer()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setFirstName(instance.firstName)
  result.setLastName(instance.lastName)
  if (instance?.middleName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.middleName)
    result.setMiddleName(wrapped)
  }
  result.setCompanyDisplayName(instance.companyDisplayName)
  if (instance?.zip) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.zip)
    result.setZip(wrapped)
  }
  if (instance?.countryName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.countryName)
    result.setCountryName(wrapped)
  }
  if (instance?.stateName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.stateName)
    result.setStateName(wrapped)
  }
  if (instance?.cityName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.cityName)
    result.setCityName(wrapped)
  }
  if (instance?.cityDisplayName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.cityDisplayName)
    result.setCityDisplayName(wrapped)
  }
  if (instance?.street) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.street)
    result.setStreet(wrapped)
  }
  if (instance?.buildingNumber) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.buildingNumber)
    result.setBuildingNumber(wrapped)
  }
  if (instance?.email) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.email)
    result.setEmail(wrapped)
  }
  if (instance?.phone) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.phone)
    result.setPhone(wrapped)
  }
  if (instance?.sex) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.sex)
    result.setSex(wrapped)
  }
  if (instance?.categoryName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.categoryName)
    result.setCategoryName(wrapped)
  }
  if (instance?.description) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.description)
    result.setDescription(wrapped)
  }
  return result
}

const toLawerObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount" || key == "balance") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listLawers: () => {
    const request = new ListLawersRequest();

    return new Promise((resolve, reject) => {
      client.listLawers(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            lawersList: result.lawersList.map(toLawerObject)
          })
        }
      })
    })
  },
  getLawer: (name) => {
    const request = new GetLawerRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getLawer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toLawerObject(response.toObject()))
        }
      });
    });
  },
  applyLawer: (instance) => {
    const applying = toLawerModel(instance)
    const request = new ApplyLawerRequest()
    request.setLawer(applying)
    return new Promise((resolve, reject) => {
      client.applyLawer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toLawerObject(response.toObject()))
        }
      })
    })
  },
  deleteLawer: (name) => {
    const request = new DeleteLawerRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteLawer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
