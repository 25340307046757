import { ApiUrl, getMetadata } from '../index'
import { EmailTopic } from './email_topic_pb'
import {
  ListEmailTopicsRequest,
  ApplyEmailTopicRequest
} from './email_topics_pb'
import { EmailTopicsClient } from './email_topics_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb.js'
import { Date } from '../date_pb'

let client = new EmailTopicsClient(ApiUrl, null, null);

const toEmailTopicModel = (instance) => {
  let result = new EmailTopic()
  result.setName(instance.name)
  result.setThreadIndex(instance.threadIndex)
  result.setIsRead(instance.isRead)
  if (instance?.customerName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.customerName)
    result.setCustomerName(wrapped)
  }
  if (instance?.lawerName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.lawerName)
    result.setLawerName(wrapped)
  }
  if (instance?.folderDisplayName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.folderDisplayName)
    result.setFolderDisplayName(wrapped)
  }
  if (instance?.emailLabelNamesList?.length) {
    result.setEmailLabelNamesList(instance?.emailLabelNamesList)
  }

  return result
}

const toEmailTopicObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listEmailTopics: (params) => {
    const request = new ListEmailTopicsRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    if (params?.emailLabelName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.emailLabelName)
      request.setEmailLabelName(wrapped)
    }
    if (params?.folderDisplayName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.folderDisplayName)
      request.setFolderDisplayName(wrapped)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.threadIndex) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.threadIndex)
      request.setThreadIndex(wrapped)
    }
    if ((params?.emailsList ?? []).length) {
      request.setEmailsList(params?.emailsList)
    }
    if (params.dateFrom) {
      const date = new Date()
      date.setYear(params.dateFrom.getFullYear())
      date.setMonth(params.dateFrom.getMonth() + 1)
      date.setDay(params.dateFrom.getDate())
      request.setFrom(date)
    }
    if (params.dateTo) {
      const date = new Date()
      date.setYear(params.dateTo.getFullYear())
      date.setMonth(params.dateTo.getMonth() + 1)
      date.setDay(params.dateTo.getDate())
      request.setTo(date)
    }

    return new Promise((resolve, reject) => {
      client.listEmailTopics(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            emailTopicsList: result.emailTopicsList.map(toEmailTopicObject)
          })
        }
      })
    })
  },
  applyEmailTopic: (instance) => {
    const applying = toEmailTopicModel(instance)
    const request = new ApplyEmailTopicRequest()
    if (instance?.fieldMask) { 
      const fieldMask = new FieldMask();
      fieldMask.setPathsList(instance.fieldMask); 
      request.setFieldMask(fieldMask);
    }
    request.setEmailTopic(applying)
    return new Promise((resolve, reject) => {
      client.applyEmailTopic(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toEmailTopicObject(response.toObject()))
        }
      })
    })
  }
};

export default API;
