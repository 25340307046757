// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { timestampToDate, toMap } from 'utility/utils'

import MessageAPI from 'api/email_messages'
import TopicAPI from 'api/email_topics'
import OwnerAPI from 'api/email_owners'
import LabelAPI from 'api/email_labels'
import TriggerInstanceAPI from 'api/trigger_instances'
import generator from './colors'

const folders = {
  "inbox": "INBOX",
  "sent": "INBOX.Sent",
  "draft": "INBOX.Drafts",
  "archive": "INBOX.Archive",
  "spam": "INBOX.Junk",
  "trash": "INBOX.Trash"
}

function convertKeyToValueMap(obj) {
  const valueMap = {};
  for (const [key, value] of Object.entries(obj)) {
      valueMap[value] = key;
  }
  return valueMap;
}

const folderValues = convertKeyToValueMap(folders)

export const getMailOwners = createAsyncThunk('appEmail/getMailOwners', async () => {
  const response = await OwnerAPI.listEmailOwners()
  return response.emailOwnersList.map(e => ({...e, foldersList: (e.foldersList ?? [])
    .map(f => ({ ...f, folder: folderValues[f.displayName] }))}))
})

export const getMailTopics = createAsyncThunk('appEmail/getMailTopics', async params => {
  const { emailTopicsList, totalRows } = await TopicAPI.listEmailTopics({
    ...params,
    folderDisplayName: folders[params.folder],
    search: params.q,
    customerName: params.customerName ?? (params.customer ? `customers/${params.customer}` : null)
  })
  return {
    params,
    data: emailTopicsList,
    totalRows: totalRows
  }
})

export const getMails = createAsyncThunk('appEmail/getMails', async params => {
  const response = await MessageAPI.listEmailMessages({
    ...params,
    folderDisplayName: params.folder ? folders[params.folder] : null,
    search: params.q,
  })
  return {
    params,
    data: response.emailMessagesList,
    totalRows: response.totalRows
  }
})

export const getMail = createAsyncThunk('appEmail/getMail', async name => {
  const response = await MessageAPI.getEmailMessage(name)
  if (response) {
    response.folder = folderValues[response.folderDisplayName]
  }
  return response
})

export const sendMail = createAsyncThunk('appEmail/sendMail', async (data) => {
  const { name } = await MessageAPI.applyEmailMessage(data)
  return await MessageAPI.sendEmailMessage(name)
})

const parseTopicName = (name) => {
  const result = {}
  if (!name) {
    return result
  }
  const parts = name.split("/")
  Array.from({ length: parts.length / 2 }, (_, i) => i).forEach(i => {
    const p = parts[2 * i]
    const v = parts[2 * i + 1]
    if (p === "emailTopics") {
      result.threadIndex = v
    } else if (p === "lawers") {
      result.lawerName = `${p}/${v}`
    } else if (p === "customers") {
      result.customerName = `${p}/${v}`
    }
  })
  return result
}

export const applyMailCustomer = createAsyncThunk('appEmail/applyMailCustomer', async ({ mail, customer }, { dispatch, getState }) => {
  const { threadIndex, lawerName, customerName } = parseTopicName(mail.name)
  const { emailTopicsList } = await TopicAPI.listEmailTopics({ threadIndex, lawerName, customerName })
  if (!emailTopicsList.length)
    return
  const emailTopic = emailTopicsList[0]
  emailTopic.customerName = customer.name
  const stored = TopicAPI.applyEmailTopic(emailTopic)
  await dispatch(getMailTopics(getState().email.topicsParams))
  return stored
})

export const applyTopicCustomer = createAsyncThunk('appEmail/applyTopicCustomer', async ({ topic, customer }, { dispatch, getState }) => {
  const stored = await TopicAPI.applyEmailTopic({
    ...topic,
    customerName: customer?.name,
    fieldMask: ["customer_name"]
  })
  await dispatch(getMailTopics(getState().email.topicsParams))
  return stored
})

export const applyTopicLawer = createAsyncThunk('appEmail/applyTopicLawer', async ({ topic, lawer }, { dispatch, getState }) => {
  const stored = await TopicAPI.applyEmailTopic({
    ...topic,
    lawerName: lawer?.name,
    fieldMask: ["lawer_name"]
  })
  await dispatch(getMailTopics(getState().email.topicsParams))
  return stored
})

export const updateMails = createAsyncThunk(
  'appEmail/updateMails',
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    const mails = (getState().email.currentTopic?.messages ?? getState().email.mails)
      .filter(e => emailIds.includes(e.id) && Object.keys(dataToUpdate).reduce((a, k) => a || e[k] !== dataToUpdate[k], false))
      .map(e => ({
        ...e, 
        ...dataToUpdate, 
        folderDisplayName: dataToUpdate.folder ? folders[dataToUpdate.folder] : 
          dataToUpdate.folder !== undefined ? dataToUpdate.folder : e.folderDisplayName
      }))
    for (const mail of mails) {
      await MessageAPI.applyEmailMessage({
        ...mail,
        fieldMask: [
          'folder' in dataToUpdate ? "folder_display_name" : null,
          'isRead' in dataToUpdate ? "is_read" : null,
          'customerName' in dataToUpdate ? "customer_name" : null,
          'lawerName' in dataToUpdate ? "lawer_name" : null
        ].filter(e => e)
      })
    }
    await dispatch(getMailTopics(getState().email.topicsParams))
    return {
      emailIds,
      dataToUpdate,
      data: {}
    }
  }
)

export const updateMailLabel = createAsyncThunk(
  'appEmail/updateMailLabel',
  async ({ emailIds, label }, { dispatch, getState }) => {
    await Promise.all(emailIds.map(async (id) => {
      const message = await MessageAPI.getEmailMessage(`emailMessages/${id}`)
      if (message) {
        await MessageAPI.applyEmailMessage({ 
          ...message,
          emailLabelNamesList: !(message.emailLabelNamesList ?? []).includes(label) ?
            [...message.emailLabelNamesList, label] : message.emailLabelNamesList.filter(l => l !== label),
          fieldMask: ["email_label_names"]
        })
      }
    }))
    await dispatch(getMailTopics(getState().email.topicsParams))
    return []
  }
)

export const updateTopics = createAsyncThunk(
  'appEmail/updateTopics',
  async ({ topicIds, dataToUpdate }, { dispatch, getState }) => {
    const topics = {}
    await Promise.all(topicIds.map(async (name) => {
      let topic = getState().email.topics.filter(e => e.name === name)[0]
      if (('folder' in dataToUpdate && folders[dataToUpdate.folder] !== topic.folderDisplayName) || 
          ('isRead' in dataToUpdate && dataToUpdate.isRead !== topic.isRead)) {
        topic = {
          ...topic,
          ...dataToUpdate,
          folderDisplayName: dataToUpdate.folder ? folders[dataToUpdate.folder] : 
            dataToUpdate.folder !== undefined ? dataToUpdate.folder : topic.folderDisplayName
        }
        topics[name] = 'folder' in dataToUpdate ? undefined : topic
        
        return TopicAPI.applyEmailTopic({ 
          ...topic,
          fieldMask: ['folder' in dataToUpdate ? "folder_display_name" : null, 'isRead' in dataToUpdate ? "is_read" : null].filter(e => e)
        })
      }
    }))

    await dispatch(setTopics([...getState().email.topics
      .map(t => topicIds.includes(t.name) && t.name in topics ? topics[t.name] : t).filter(e => !!e)]
    ))
    //dispatch(getMailTopics(getState().email.topicsParams))

    return {
      topicIds,
      dataToUpdate,
      data: {}
    }
  }
)

export const updateTopicLabel = createAsyncThunk(
  'appEmail/updateTopicLabel',
  async ({ topicIds, label, include }, { dispatch, getState }) => {
    const topics = {}
    await Promise.all(topicIds.map(async (name) => {
      let topic = getState().email.topics.filter(e => e.name === name)[0]
      if ((include && !(topic.emailLabelNamesList ?? []).includes(label)) || 
          (!include && (topic.emailLabelNamesList ?? []).includes(label))) {
        const emailLabelNamesList = include ? [...topic.emailLabelNamesList, label] :
          topic.emailLabelNamesList.filter(l => l !== label)
        topic = {
          ...topic,
          emailLabelNamesList
        }
        topics[name] = topic

        return TopicAPI.applyEmailTopic({ 
          ...topic,
          fieldMask: ["email_label_names"]
        })
      }
    }))

    await dispatch(setTopics([...getState().email.topics.map(t => topicIds.includes(t.name) ? topics[t.name] : t)]))
    dispatch(getMailTopics(getState().email.topicsParams))

    return []
  }
)

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
  const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
  return response.data
})

export const selectCurrentMail = createAsyncThunk('appEmail/selectCurrentMail', async id => {
  const mail = await MessageAPI.getEmailMessage(`emailMessages/${id}`)
  if (mail.parentName) {
    const { emailMessagesList } = await MessageAPI.listEmailMessages({
      threadIndex: mail.topicThreadIndex,
    })
    const replies = []
    let parentName = mail.parentName
    while (parentName) {
      const reply = emailMessagesList.filter(e => e.name === parentName)[0]
      if (!reply) {
        break
      }
      replies.push(reply)
      parentName = reply.parentName
    }
    mail.replies = replies
  }
  return mail
})

export const selectCurrentTopic = createAsyncThunk('appEmail/selectCurrentTopic', async (name, { getState, dispatch }) => {
  const topic = getState().email.topics.filter(e => e.name === name)[0]
  if (!topic) return null

  const { threadIndex, lawerName, customerName } = parseTopicName(name)
  const { emailMessagesList } = await MessageAPI.listEmailMessages({ threadIndex, lawerName, customerName })
  emailMessagesList.sort((a, b) => timestampToDate(a.date) - timestampToDate(b.date))

  let replyAttachments = []
  if (topic.customerName) {
    const { triggerinstancesList } = await TriggerInstanceAPI.listTriggerInstances({ customerName: topic.customerName })
    replyAttachments = [...new Set([].concat(
      ...triggerinstancesList.filter(e => e.executedAt)
        .map(e => Object.entries(toMap(e.parametersMap))
        .map(([key, value]) => key.startsWith("attachment") ? value : null).filter(e => e)))
      .map(url => {
        const parts = url.split('/')
        return parts[parts.length - 1]
      }))]
  }
  return {
    ...topic,
    messages: emailMessagesList,
    replyAttachments
  }
})

export const listLabels = createAsyncThunk('appEmail/listLabels', async () => {
  const response = await LabelAPI.listEmailLabels()
  return response.emailLabelsList
})

export const upsertLabel = createAsyncThunk('appEmail/applyLabel', async (label, { dispatch, getState }) => {
  let color
  if (!label?.color) {
    color = generator.generateUniqueColor(getState().email.labels.map(l => l.color))
  }
  const result = await LabelAPI.applyEmailLabel({ ...label, color: color ?? label.color })
  await dispatch(listLabels())
  return result
})

export const deleteLabel = createAsyncThunk('appEmail/deleteLabel', async ({ name }, { dispatch }) => {
  await LabelAPI.deleteEmailLabel(name)
  await dispatch(listLabels())
})

export const appEmailSlice = createSlice({
  name: 'appEmail',
  initialState: {
    mails: [],
    owners: [],
    params: {},
    emailsMeta: {},
    selectedMails: [],
    currentMail: null,
    currentTopic: null,
    topics: [],
    selectedTopics: [],
    labels: [],
    topicsParams: {}
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
    },
    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach(mail => selectAllMailsArr.push(mail.id))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },
    resetSelectedMail: state => {
      state.selectedMails = []
    },
    selectTopic: (state, action) => {
      const selectedTopics = state.selectedTopics
      if (!selectedTopics.includes(action.payload)) {
        selectedTopics.push(action.payload)
      } else {
        selectedTopics.splice(selectedTopics.indexOf(action.payload), 1)
      }
      state.selectedTopics = selectedTopics
    },
    selectAllTopics: (state, action) => {
      const selectAllTopicsArr = []
      if (action.payload) {
        selectAllTopicsArr.length = 0
        state.topics.forEach(topic => selectAllTopicsArr.push(topic.name))
      } else {
        selectAllTopicsArr.length = 0
      }
      state.selectedTopics = selectAllTopicsArr
    },
    resetSelectedTopic: state => {
      state.selectedTopics = []
    },
    setTopics: (state, action) => {
      state.topics = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getMails.fulfilled, (state, action) => {
        let currMail = null
        if (state.currentMail !== null && state.currentMail !== undefined) {
          currMail = action.payload.data.find(i => i.id === state.currentMail.id)
        }
        state.currentMail = currMail
        state.params = action.payload.params
        state.mails = action.payload.data
        state.emailsMeta = {}
        state.total = action.payload.totalRows
      })
      .addCase(getMailOwners.fulfilled, (state, action) => {
        state.owners = action.payload
      })
      .addCase(getMailTopics.fulfilled, (state, action) => {
        state.topicsParams = action.payload.params
        state.topics = action.payload.data
        state.topicsTotal = action.payload.totalRows
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach(email => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(updateTopics.fulfilled, (state, action) => {
        function updateTopicData(topic) {
          Object.assign(topic, action.payload.dataToUpdate)
        }
        state.topics.forEach(topic => {
          if (action.payload.topicIds.includes(topic.name)) {
            updateTopicData(topic)
          }
        })
      })
      .addCase(updateTopicLabel.fulfilled, (state, action) => {
        if (state.currentTopic) {
          const topic = state.topics.filter(t => t.name === state.currentTopic.name)[0]
          if (topic) {
            state.currentTopic = {
              ...state.currentTopic,
              ...topic
            }
          }
        }
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex(i => i.id === data.id)
        dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.currentMail = action.payload
      })
      .addCase(selectCurrentTopic.fulfilled, (state, action) => {
        state.currentTopic = action.payload
      })
      .addCase(listLabels.fulfilled, (state, action) => {
        state.labels = action.payload
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail, selectTopic, selectAllTopics, resetSelectedTopic, setTopics } = appEmailSlice.actions

export default appEmailSlice.reducer
