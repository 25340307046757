// ** React Imports
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouterOutlet } from 'react-router-outlet'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@core/layouts/VerticalLayout'

// ** Menu Items Array
import navigation from 'navigation/vertical'

import {
  getMailOwners
} from 'views/activities/email/store'

const VerticalLayout = props => {
  const [menuData, setMenuData] = useState(navigation)
  const [requesting, setRequesting] = useState(false)
  const [emailBadge, setEmailBadge] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    setMenuData(menuData => {
      const updatedMenu = [...menuData]
      const emailItem = updatedMenu.find(e => e.id === "email")
      if (emailItem) emailItem.badgeText = emailBadge
      return updatedMenu
    })
  }, [emailBadge])
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (requesting) return
      setRequesting(true)
      dispatch(getMailOwners()).then(({ payload }) => {
        const latestCount = ((payload ?? [])[0]?.foldersList ?? []).filter(e => e.folder === "inbox")[0]?.latestCount
        setEmailBadge(prev => (prev !== latestCount ? latestCount : prev))
        setRequesting(false)
      })
    }, 5000)

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  return (
    <Layout menuData={menuData} {...props}>
      <RouterOutlet routes={props.routes} />
    </Layout>
  )
}

export default VerticalLayout
