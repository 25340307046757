// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import countries from 'views/locations/countries/store'
import states from 'views/locations/states/store'
import cities from 'views/locations/cities/store'
import customers from 'views/users/customers/store'
import profiles from 'views/users/profiles/store'
import products from 'views/brands/products/store'
import legals from 'views/brands/legals/store'
import subscriptions from 'views/brands/subscriptions/store'
import customerCategories from 'views/references/customer-categories/store'
import lawerCategories from 'views/references/lawer-categories/store'
import chat from 'views/activities/chat/store'
import email from 'views/activities/email/store'
import invoices from 'views/financial/invoices/store'
import payments from 'views/financial/payments/store'
import invoicePayees from 'views/financial/invoice-payees/store'
import companies from 'views/financial/companies/store'
import invoiceTypes from 'views/references/invoice-types/store'
import paymentTypes from 'views/references/payment-types/store'
import affilateSystems from 'views/references/affilate-systems/store'
import emailMessageTemplates from 'views/references/email-message-templates/store'
import emailMessageTemplateParams from 'views/references/email-message-template-params/store'
import collectionAgencies from 'views/collections/collection-agencies/store'
import collectionExports from 'views/collections/collection-exports/store'
import affilateConversions from 'views/users/affilates/store'
import customerActionStatuses from 'views/references/action-statuses/store'
import lawers from 'views/users/lawers/store'

const rootReducer = {
  auth,
  countries,
  states,
  cities,
  customers,
  lawers,
  profiles,
  customerCategories,
  lawerCategories,
  products,
  legals,
  subscriptions,
  chat,
  email,
  invoiceTypes,
  paymentTypes,
  invoices,
  payments,
  invoicePayees,
  companies,
  emailMessageTemplates,
  emailMessageTemplateParams,
  collectionAgencies,
  collectionExports,
  affilateSystems,
  affilateConversions,
  customerActionStatuses,

  navbar,
  layout
}

export default rootReducer
