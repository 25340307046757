import { ApiUrl, getMetadata } from '../index'
import { LawerCategoriesClient } from './lawer_categories_grpc_web_pb'
import { LawerCategory } from './lawer_category_pb'
import {
  GetLawerCategoryRequest,
  ListLawerCategoriesRequest,
  ApplyLawerCategoryRequest,
  DeleteLawerCategoryRequest
} from './lawer_categories_pb'

let client = new LawerCategoriesClient(ApiUrl, null, null);

const toLawerCategoryModel = (instance) => {
  let result = new LawerCategory()
  result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setIsActive(instance.isActive)
  return result
}

const API = {
  listLawerCategories: () => {
    const request = new ListLawerCategoriesRequest();

    return new Promise((resolve, reject) => {
      client.listLawerCategories(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject().lawerCategoriesList)
        }
      })
    })
  },
  getLawerCategory: (name) => {
    const request = new GetLawerCategoryRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getLawerCategory(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyLawerCategory: (instance) => {
    const applying = toLawerCategoryModel(instance)
    const request = new ApplyLawerCategoryRequest()
    request.setLawerCategory(applying)
    return new Promise((resolve, reject) => {
      client.applyLawerCategory(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteLawerCategory: (name) => {
    const request = new DeleteLawerCategoryRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteLawerCategory(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
