// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/email_message_template_params'
import { getFileUrl } from 'api'

import { toMap } from 'utility/utils'

export const contextHelpers = {
  formatDate: date => {
    if (!date)
      return ""
    return new Intl.DateTimeFormat("de-DE").format(date)
  },
  toDate: val => {
    if (!val)
      return null
    return new Date(val.year, val.month - 1, val.day)
  },
  formatAmount: amt => {
    if (!amt) {
      return ""
    }
    const val = amt.value / Math.pow(10, -amt.exponent)
    return val.toLocaleString('de-DE', { 
      minimumFractionDigits: 0,
      style: 'currency', 
      currency: amt.currencyName.replace('currencies/', '').toUpperCase()
    })
  }
}

export const testUnknownContext = {
  product: {
    //"name": "products/1",
    //"id": 1,
    "displayName": "Datebook",
    "domain": "www.datebook.gold",
    //"logoUrl": "minio://products/1/logos/DATEBOOK_ICON.png",
    "supportEmail": "support@datebook.live",
    //"isActive": true,
    //"blocksList": [],
    //"legalNamesList": ["legals/2","legals/1","legals/3"],
    //"invoicePayeeNamesList": []
  }
}

export const testCustomerContext = {
  customer: {
    //"name": "customers/0",
    //"id": 0,
    "displayName": "Michael Lörcher",
    "number": "DH000000",
    "firstName": "Michael",
    "lastName": "Lörcher",
    "zip": "00000",
    //"countryName": "countries/de",
    //"stateName": "states/berlin",
    //"cityName": "cities/berlin",
    "cityDisplayName": "Berlin",
    "street": "Tomerauerst",
    "buildingNumber": "0",
    "email": "test@test.com",
    "mobile": "+491111111111",
    "registrationIp": "0.0.0.0",
    //"productName": "products/1",
    //"subscriptionName": "subscriptions/1",
    "dateOfSubscription": {
        "year": 2025,
        "month": 2,
        "day": 3
    },
    "sex": "man",
    "lookingFor": "woman",
    "device": "iPhone",
    "os": "iOS 18.1",
    "browser": "Mobile Safari 18.1",
    //"status": "trial",
    //"selfieUrl": "minio://test.png",
    //"photoUrlsMap": [],
    //"isInternal": false,
    //"categoryValuesList": [],
    //"lookingCategoryValuesList": [],
    /*"valuesMap": [
        [
            "Connected",
            "Online"
        ],
        [
            "Distance",
            "2.6km"
        ],
        [
            "geo_city_display_name",
            "Δήμος Αγίου Αθανασίου"
        ],
        [
            "geo_country_code",
            "cy"
        ],
        [
            "geo_latitude",
            "34.69593299022613"
        ],
        [
            "geo_longitude",
            "33.0697672585505"
        ],
        [
            "geo_state_display_name",
            "Zypern"
        ],
        [
            "geo_zip",
            "4103"
        ],
        [
            "registration_building_number",
            "12"
        ],
        [
            "registration_city_display_name",
            "Berlin"
        ],
        [
            "registration_email",
            "init@user.com"
        ],
        [
            "registration_first_name",
            "Michael"
        ],
        [
            "registration_last_name",
            "Lörcher"
        ],
        [
            "registration_street",
            "Tomerauerst"
        ],
        [
            "registration_zip",
            "00000"
        ]
    ],*/
    "createdAt": {
        "seconds": 1738612953,
        "nanos": 397621000
    },
    "updatedAt": {
        "seconds": 1738612953,
        "nanos": 397621000
    },
    //"isModerated": true,
    //"isMobileVerified": true,
    //"isEmailVerified": true,
    //"isMobileConfirmed": true,
    //"isEmailConfirmed": true,
    "registrationUserAgent": "Mozilla/5.0 (iPhone; CPU iPhone OS 18_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/18.1 Mobile/15E148 Safari/604.1",
    //"customerActionStatusName": "customerActionStatuses/Active",
    //"invoicePayeeName": "invoicePayees/1",
    "courtAmount": {
        "value": 10000,
        "currencyName": "currencies/eur",
        "exponent": -2
    },
    notificationsList: [
      {
        "type": "reminder",
        "date": {
          "year": 2025,
          "month": 2,
          "day": 27
        }
      },
      {
        "type": "reminder",
        "date": {
          "year": 2025,
          "month": 3,
          "day": 7
        }
      },
      {
        "type": "payment",
        "date": {
          "year": 2025,
          "month": 3,
          "day": 3
        }
      },
      {
        "type": "payment",
        "date": {
          "year": 2025,
          "month": 3,
          "day": 7
        }
      },
      {
        "type": "payment",
        "date": {
          "year": 2025,
          "month": 3,
          "day": 14
        }
      },
      {
        "type": "inkasso",
        "date": {
          "year": 2025,
          "month": 3,
          "day": 16
        }
      },
      {
        "type": "payment",
        "date": {
          "year": 2025,
          "month": 3,
          "day": 23
        }
      }
    ],
    "payment_url": "datebook.com/test",
    "mahnung_url": "https://mahnung.pdf",
    "proforma_url": "https://proforma.pdf"
  },
  beneficiary: {
    //"name": "invoicePayees/1",
    //"id": 1,
    "displayName": "Terra Nova (Volksbank)",
    //"companyName": "companies/1",
    "bankName": "Volksbank Dreiländereck",
    "bankIban": "DE80683900000009061940",
    "bankBic": "VOLODE66",
    "requisites": "Berlin branch",
    //"isActive": true
  },
  subscription: {
    //"name": "subscriptions/1",
    //"id": 1,
    "displayName": "Goldenpass",
    //"productName": "products/1",
    //"durationValue": 2,
    "durationUnitCode": "yearly",
    "earlyCancellationDays": 14,
    "tariff": {
        "value": 98000,
        "currencyName": "currencies/eur",
        "exponent": -2
    },
    //"requiredCustomerPropertiesList": [],
    //"isActive": true
  },
  invoice: {
    "amount": {
        "value": 49000,
        "currencyName": "currencies/eur",
        "exponent": -2
    },
    "date": {
        "year": 2025,
        "month": 2,
        "day": 17
    },
  },
  company: {
    //"name": "companies/1",
    //"id": 1,
    "displayName": "Terra Nova Solutions UG",
    "vat": "DE368555976",
    "registrationNumber": "HRB 792666",
    //"cityName": "cities/stuttgart",
    "email": "support@datebook.live",
    "phone": "+490000000000",
    "address": "Löffelstraße 22-24, 70597 Stuttgart",
    //"isActive": true
  },
  collectionAgency: {
    "displayName": "Collector",
  },
  ...testUnknownContext
}

export const testLawerContext = {
  lawer: {
    "displayName": "Michael Lörcher",
    "firstName": "Michael",
    "lastName": "Lörcher",
    "zip": "00000",
    //"countryName": "countries/de",
    //"stateName": "states/berlin",
    //"cityName": "cities/berlin",
    "cityDisplayName": "Berlin",
    "street": "Tomerauerst",
    "buildingNumber": "0",
    "email": "test@test.com",
    "mobile": "+491111111111",
    "sex": "man"
  },
  ...testUnknownContext
}

export const testContexts = {
  '': testUnknownContext,
  'customer': testCustomerContext,
  'lawer': testLawerContext
}

export const evaluateFormula = (formula, context) => {
  context = { ...context, ...contextHelpers }
  const contextKeys = Object.keys(context)

  const argNames = [...contextKeys, 'formula']
  const argValues = [...contextKeys.map(key => context[key]), formula]

  const func = new Function(...argNames, 'return eval(formula);')
  return func(...argValues)
}

const escapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const render = createAsyncThunk('appEmailMessageTemplateParams/render', ({ text, context, handleParam }, { dispatch, getState }) => {
  if (!text)
    return text

  let result = text
  for (const p of getState().emailMessageTemplateParams.data) {
    if (!text.toLowerCase().includes(p.displayName.toLowerCase())) {
      continue
    }
    try {
      let value = evaluateFormula(p.formula, context)
      const regex = new RegExp(escapeRegExp(p.displayName), 'gi')
      if (handleParam && p.editable) {
        value = handleParam(p.displayName, value)
      }
      result = result.replaceAll(regex, value)
      //result = result.replaceAll(p.displayName, value)
    } catch (err) {
      return `Error in parameter ${p.displayName}: ${err.message}`
    }
  }

  return result
})

export const list = createAsyncThunk('appEmailMessageTemplateParams/list', async () => {
  return (await API.listEmailMessageTemplateParams()).emailMessageTemplateParamsList
})

export const get = createAsyncThunk('appEmailMessageTemplateParams/get', async name => {
  return await API.getEmailMessageTemplateParam(name)
})

export const add = createAsyncThunk('appEmailMessageTemplateParams/add', async (instance, { dispatch }) => {
  const updated = await API.applyEmailMessageTemplateParam(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const generate = createAsyncThunk('appEmailMessageTemplateParams/generate', async customerName => {
  const { valuesMap } = await API.generateEmailMessageTemplateParam({ customerName })
  const values = toMap(valuesMap)
  Object.keys(values).forEach(k => {
    if (k.endsWith("_url") && values[k]?.startsWith("minio://")) {
      values[k] = getFileUrl(values[k])
    }
  })

  return values
})

export const modify = createAsyncThunk('appEmailMessageTemplateParams/modify', async (instance, { dispatch }) => {
  const updated = await API.applyEmailMessageTemplateParam(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appEmailMessageTemplateParamsSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appEmailMessageTemplateParams/del', async (name, { dispatch }) => {
  await API.deleteEmailMessageTemplateParam(name)
  await dispatch(list())
  return name
})

export const appEmailMessageTemplateParamsSlice = createSlice({
  name: 'appEmailMessageTemplateParams',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appEmailMessageTemplateParamsSlice.reducer
