// source: projects/erp/service/api/v1/model/lawer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.dating.erp.api.v1.model.Lawer', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.model.Lawer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.model.Lawer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.model.Lawer.displayName = 'proto.dating.erp.api.v1.model.Lawer';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.model.Lawer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.model.Lawer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.model.Lawer.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    middleName: (f = msg.getMiddleName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zip: (f = msg.getZip()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    countryName: (f = msg.getCountryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    stateName: (f = msg.getStateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    cityName: (f = msg.getCityName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    cityDisplayName: (f = msg.getCityDisplayName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    street: (f = msg.getStreet()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buildingNumber: (f = msg.getBuildingNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    phone: (f = msg.getPhone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sex: (f = msg.getSex()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    categoryName: (f = msg.getCategoryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    companyDisplayName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.model.Lawer}
 */
proto.dating.erp.api.v1.model.Lawer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.model.Lawer;
  return proto.dating.erp.api.v1.model.Lawer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.model.Lawer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.model.Lawer}
 */
proto.dating.erp.api.v1.model.Lawer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddleName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setZip(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCountryName(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStateName(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCityName(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCityDisplayName(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStreet(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuildingNumber(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhone(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSex(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCategoryName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDisplayName(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.model.Lawer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.model.Lawer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.model.Lawer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMiddleName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZip();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCountryName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStateName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCityName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCityDisplayName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreet();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuildingNumber();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSex();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCategoryName();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCompanyDisplayName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue middle_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getMiddleName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setMiddleName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearMiddleName = function() {
  return this.setMiddleName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasMiddleName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue zip = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getZip = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setZip = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearZip = function() {
  return this.setZip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasZip = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue country_name = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getCountryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setCountryName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearCountryName = function() {
  return this.setCountryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasCountryName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue state_name = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getStateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setStateName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearStateName = function() {
  return this.setStateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasStateName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue city_name = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getCityName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setCityName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearCityName = function() {
  return this.setCityName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasCityName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue city_display_name = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getCityDisplayName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setCityDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearCityDisplayName = function() {
  return this.setCityDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasCityDisplayName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue street = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getStreet = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setStreet = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearStreet = function() {
  return this.setStreet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasStreet = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue building_number = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getBuildingNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setBuildingNumber = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearBuildingNumber = function() {
  return this.setBuildingNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasBuildingNumber = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue email = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue phone = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getPhone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setPhone = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearPhone = function() {
  return this.setPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue sex = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getSex = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setSex = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearSex = function() {
  return this.setSex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasSex = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue category_name = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getCategoryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setCategoryName = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearCategoryName = function() {
  return this.setCategoryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasCategoryName = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string company_display_name = 18;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getCompanyDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.setCompanyDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional google.protobuf.StringValue description = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
*/
proto.dating.erp.api.v1.model.Lawer.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Lawer} returns this
 */
proto.dating.erp.api.v1.model.Lawer.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Lawer.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 19) != null;
};


goog.object.extend(exports, proto.dating.erp.api.v1.model);
