import { ApiUrl, getMetadata } from '../index'
import { EmailMessageTemplateParam } from './email_message_template_param_pb'
import {
  GetEmailMessageTemplateParamRequest,
  ListEmailMessageTemplateParamsRequest,
  ApplyEmailMessageTemplateParamRequest,
  DeleteEmailMessageTemplateParamRequest,
  GenerateEmailMessageTemplateParamRequest
} from './email_message_template_params_pb'
import { EmailMessageTemplateParamsClient } from './email_message_template_params_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new EmailMessageTemplateParamsClient(ApiUrl, null, null);

const toEmailMessageTemplateParamModel = (instance) => {
  let result = new EmailMessageTemplateParam()
  result.setName(instance.name)
  
  result.setDisplayName(instance.displayName)
  result.setFormula(instance.formula)
  result.setType(instance.type)
  result.setEditable(instance.editable)

  return result
}

const toEmailMessageTemplateParamObject = (instance) => {
  if (!instance) return instance
  return {
    ...instance
  }
}

const API = {
  listEmailMessageTemplateParams: (params) => {
    const request = new ListEmailMessageTemplateParamsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    
    return new Promise((resolve, reject) => {
      client.listEmailMessageTemplateParams(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            emailMessageTemplateParamsList: result.emailMessageTemplateParamsList.map(e => toEmailMessageTemplateParamObject(e))
          })
        }
      });
    });
  },
  getEmailMessageTemplateParam: (name) => {
    const request = new GetEmailMessageTemplateParamRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getEmailMessageTemplateParam(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(toEmailMessageTemplateParamObject(response.toObject()))
        }
      });
    });
  },
  applyEmailMessageTemplateParam: (instance) => {
    const applying = toEmailMessageTemplateParamModel(instance)
    const request = new ApplyEmailMessageTemplateParamRequest()
    request.setEmailMessageTemplateParam(applying)
    return new Promise((resolve, reject) => {
      client.applyEmailMessageTemplateParam(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteEmailMessageTemplateParam: (name) => {
    const request = new DeleteEmailMessageTemplateParamRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteEmailMessageTemplatePAram(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  generateEmailMessageTemplateParam: ({ customerName }) => {
    const request = new GenerateEmailMessageTemplateParamRequest()
    request.setCustomerName(customerName)
    return new Promise((resolve, reject) => {
      client.generateEmailMessageTemplateParam(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
};

export default API;
