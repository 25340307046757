import { ApiUrl, getMetadata } from '../index'
import { EmailLabel } from './email_label_pb'
import {
  GetEmailLabelRequest,
  ListEmailLabelsRequest,
  ApplyEmailLabelRequest,
  DeleteEmailLabelRequest
} from './email_labels_pb'
import { EmailLabelsClient } from './email_labels_grpc_web_pb'

let client = new EmailLabelsClient(ApiUrl, null, null);

const toEmailLabelModel = (instance) => {
  let result = new EmailLabel()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setColor(instance.color)
  result.setType(instance.type)

  return result
}

const API = {
  listEmailLabels: () => {
    const request = new ListEmailLabelsRequest()
    return new Promise((resolve, reject) => {
      client.listEmailLabels(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getEmailLabel: (name) => {
    const request = new GetEmailLabelRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getEmailLabel(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyEmailLabel: (instance) => {
    const applying = toEmailLabelModel(instance)
    const request = new ApplyEmailLabelRequest()
    request.setEmailLabel(applying)
    return new Promise((resolve, reject) => {
      client.applyEmailLabel(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteEmailLabel: (name) => {
    const request = new DeleteEmailLabelRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteEmailLabel(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
