// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import API from 'api/lawers'

export const list = createAsyncThunk('appLawers/list', async () => {
  return (await API.listLawers()).lawersList
})

export const get = createAsyncThunk('appLawers/get', async name => {
  return await API.getLawer(name)
})

export const add = createAsyncThunk('appLawers/add', async (instance, { dispatch }) => {
  const updated = await API.applyLawer(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appLawers/modify', async (instance, { dispatch }) => {
  const updated = await API.applyLawer(instance)
  instance = {
    ...instance,
    updated
  }
  await dispatch(appLawersSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appLawers/del', async (name, { dispatch }) => {
  await API.deleteLawer(name)
  await dispatch(list())
  return name
})

export const appLawersSlice = createSlice({
  name: 'appLawers',
  initialState: {
    data: [],
    selected: null
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
        state.total = action.payload.length
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appLawersSlice.reducer
